import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'InventoryLocationMappingDetails',
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.getOrganizationList();
    },
    currentPage: function() {
      this.getOrganizationList();
    }
  },
  props: ['invLocMpngDtl'],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      showOrgMaster: false,
      selectedIndex: 0,
      organizationListData: [],
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      pageOptions: commonHelper.getPageOption(),
      fields: [
        {
          key: 'org_type',
          label: 'Organization Type'
        },
        {
          key: 'org_name',
          label: 'Organization Name'
        },
        {
          key: 'org_short_name',
          class: 'd-none'
        },
        {
          key: 'parent_name',
          class: 'd-none'
        },
        {
          key: 'org_description',
          class: 'd-none'
        },
        {
          key: 'address_name',
          class: 'd-none'
        },
        {
          key: 'address',
          class: 'd-none'
        },
        {
          key: 'org_id',
          class: 'd-none'
        }
      ],
      isSuccess: false,
      showAlert: false,
      editMode: false,
      orgNameSearch: '',
      responseMsg: '',
      showValueSetModal: false,
      vsetCode: null,
      orgTypeLookup: null,
      inventory: null,
      inventoryShortName: null,
      leName: null,
      groupName: null,
      ouName: null,
      invId: null,
      ouId: null,
      leId: null,
      groupId: null,
      inventoryLocationMpngData: [
        {
          org_loc_inv_mpng_id: 0,
          location: null,
          loc_org_id: 0,
          active: true
        }
      ],
      inventoryLocationMpngFields: [
        {
          key: 'location'
        },
        {
          key: 'active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.invLocMpngDtl) {
      this.fillInventoryLocationMappingData(this.invLocMpngDtl.inv_id);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditInventoryLocation();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    fillInventoryLocationMappingData(inventory_id) {
      this.loader = true;
      const payload = inventory_id;
      this.$store
        .dispatch('inventoryLocationMapping/getInventoryLocationById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.orgTypeLookup = results.org_type_lookup;
            this.inventory = results.inventory;
            this.invId = results.inv_id;
            this.ouId = results.ou_id;
            this.inventoryShortName = results.org_short_name;
            this.leId = results.le_id;
            this.groupId = results.group_id;
            this.leName = results.le_name;
            this.groupName = results.group_name;
            this.ouName = results.ou_name;
            if (results.inventory_location_hdr_details.length > 0) {
              this.inventoryLocationMpngData =
                results.inventory_location_hdr_details;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditInventoryLocation() {
      const details = this.inventoryLocationMpngData.map(element => {
        return {
          active: element.active, //NotNull
          location_organization_id: Number(element.loc_org_id), //NotNull
          organization_location_inventory_mapping_id:
            element.org_loc_inv_mpng_id //NotNull
        };
      });
      const payload = {
        inventory_id: this.invId,
        inventory_location_mapping_details: {
          inventory_location_mapping_details: details ? details : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('inventoryLocationMapping/addEditInventoryLocation', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getOrganizationList() {
      this.loader = true;
      const queryParams = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        org_type: 'LOC',
        org_short_name: null,
        org_name: this.orgNameSearch,
        parent_org_name: null
      };
      this.$store
        .dispatch('organization/getOrganizationList', queryParams)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.organizationListData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearOrgSearchFields() {
      this.orgNameSearch = null;
    },
    addNewRow() {
      if (this.editMode) {
        this.inventoryLocationMpngData.push({
          org_loc_inv_mpng_id: 0,
          location: null,
          loc_org_id: 0,
          active: true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.inventoryLocationMpngData.splice(index, 1);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.mappingIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.inventoryLocationMpngData[this.mappingIndex].location =
          item.value_code;
        this.inventoryLocationMpngData[this.mappingIndex].loc_org_id =
          item.value_set_dtl_id;
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.inventoryLocationMpngData[this.selectedIndex].location =
        item.org_name;

      this.inventoryLocationMpngData[this.selectedIndex].loc_org_id =
        item.org_id;
      this.$emit('selectedOrg', item);
      this.showOrgMaster = false;
    },
    hideOrgMaster(flag, index) {
      this.selectedIndex = index;
      if (flag) {
        this.getOrganizationList();
      }
      this.showOrgMaster = flag;
      this.$emit('closeOrgMaster', this.showOrgMaster);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
