import commonHelper from '@/app/utility/common.helper.utility';
import InventoryLocationMappingModal from './inventoryLocationMappingDetails';
export default {
  name: 'InventoryOrgLocationMapping',
  watch: {
    perPage: function() {
        this.currentPage = 1;
        this.getInvLocMpngList();
    },
      currentPage: function() {
        this.getInvLocMpngList();
      }
  },
  components: {
    InventoryLocationMappingModal
  },
  data() {
    return {
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      inventory: null,
      showInventoryLocationMpngModal: false,
      loader: false,
      inventoryLocationMpngDtl: null,
      inventoryLocationMpngData: [],
      inventoryLocationMpngFields: [
        {
          key: 'group_name',
          label: 'Group Name'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'inventory',
          label: 'Inventory'
        },
        {
          key: 'org_short_name',
          label: 'Inventory Short Name'
        }
      ]
    };
  },
  mounted() {
    this.getInvLocMpngList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showInventoryLocationMpngModal
        ) {
          this.loader = true;
          const downloadPayload = { ...this.payload };
          downloadPayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadPayload,
            'inventoryLocationMapping/getInventoryLocationMappingList',
            'inventory-org-location-mapping',
            () => {
              this.loader = false;
            }
          );
        }
      }
    });
  },
  methods: {
    getInvLocMpngList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        inv_name: this.inventory,
      };
      this.loader = true;
      this.$store
        .dispatch(
          'inventoryLocationMapping/getInventoryLocationMappingList',
          this.payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.inventoryLocationMpngData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.inventoryLocationMpngDtl = item;
      this.showHideInvLocMpngModal(true);
    },
    showHideInvLocMpngModal(flag) {
      this.showInventoryLocationMpngModal = flag;
    },
    clearLocation() {
      this.inventory = null;
      this.getInvLocMpngList();
    }
  },
  beforeDestroy() {
      this.unsubscribe();
  }
};
